<template>
    <div>
    <b-card-code no-body :title="'Completed questionnaires'">
        <div class="m-2">
            <div class="d-flex align-items-center justify-content-between">
                <!-- Per Page -->
                <div cols="6" md="6" class="d-flex">
                    <!-- order by -->
                    <b-form-group
                        label="Order by time completed"
                        label-cols="7"
                        label-align="left"
                        label-size="sm"
                        label-for="orderBy"
                        class="text-nowrap mb-md-0 mr-1 d-flex align-items-center"
                    >
                        <b-form-select
                        id="orderBySelect"
                        v-model="orderBy"
                        size="sm"
                        inline
                        :options="orderByOptions"
                        @change="getAllCompletedQuestionnaires()"
                        />
                    </b-form-group>
                    <!-- has reactions -->
                    <b-form-group
                        label="Reactions"
                        label-cols="4"
                        label-align="left"
                        label-size="sm"
                        label-for="orderBy"
                        class="text-nowrap mb-md-0 mr-1 d-flex align-items-center"
                    >
                        <b-form-select
                        id="hasReactionsSelect"
                        v-model="hasReactions"
                        size="sm"
                        inline
                        :options="hasReactionsOptions"
                        @change="getAllCompletedQuestionnaires()"
                        />
                    </b-form-group>
                </div>
                <div>
                    <b-form-input
                        style="max-width:250px"
                        id="input-23"
                        v-model="searchFeed"
                        type="text"
                        size="sm"
                        placeholder="Search"
                        required
                        v-debounce:500ms="searchResults" 
                        debounce-events="input"
                    />
                </div>
            </div>
        </div>
        <b-overlay :show="show" rounded="sm">
            <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="perPage"
                :items="data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
            >
                <!-- Column: Category -->
                <template #cell(subcategory)="data">
                    <router-link :to="{name: 'questionnaire-submission', params: {submission_id: data.item.id} }">
                        <span class="font-weight-bold d-block text-nowrap">
                            {{ data.item.userquestionnairetook.questionnaire.title }}
                        </span>
                    </router-link>
                </template>
                
                <!-- Column: Subategory -->
                <template #cell(category)="data">
                    <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">{{
                        data.item.userquestionnairetook.questionnaire.subcategory.questionnairecategory.name
                    }}</span>
                    </div>
                </template>
                
                <!-- Column: User -->
                <template #cell(user)="data">
                    <div class="text-nowrap">
                    <span class="align-text-top">{{
                        data.item.userquestionnairetook.user.username
                    }}</span>
                    </div>
                </template>
            
                <!-- Column: Score -->
                <template #cell(score)="data">
                    <div class="text-nowrap">
                    <span class="align-text-top"><b-badge pill class="text-capitalize">{{data.item.score.section_score}}</b-badge></span>
                    </div>
                </template>
                
                <!-- Column: Messages -->
                <template #cell(messages)="data">
                    <div class="text-nowrap">
                    <span class="align-text-top">
                        <b-badge pill class="text-capitalize">{{data.item.grouped_messages.length}}</b-badge></span>
                    </div>
                </template>

                <!-- Column: Reactions -->
                <template #cell(reactions)="data">
                    <div class="text-nowrap">
                    <span class="align-text-top"><b-badge pill class="text-capitalize">{{data.item.grouped_reactions.length}}</b-badge></span>
                    </div>
                </template>
                
                <!-- Column: Completed -->
                <template #cell(completed)="data">
                    <span v-if="data.item.updated_at == '0 days ago'">
                        Today
                    </span>
                    <span v-else>
                        {{ data.item.updated_at | prepareDate }}
                    </span>
                </template>
            </b-table>
        </b-overlay>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
                <span>
                    <b-button
                    variant="outline-secondary"
                    class="btn-icon rounded-circle"
                    @click="changePage('prev')"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                    </b-button>
                </span>
                <span class="mx-1">
                    <b-button
                    variant="outline-primary"
                    class="btn-icon rounded-circle px-1"
                    disabled
                    >
                        {{ currentPage }}
                    </b-button>
                </span>
                <span>
                    <b-button
                    variant="outline-secondary"
                    class="btn-icon rounded-circle"
                    @click="changePage('next')"
                    >
                        <feather-icon icon="ChevronRightIcon" size="18" />
                    </b-button>
                </span>
            </div>
        </b-card-body>
    </b-card-code>
    </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vueDebounce from 'vue-debounce'

import {
    BOverlay,
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
export default {
    components: {
        BOverlay,
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardCode,
    },
    data() {
        return {
            show: true,
            data: [],
            perPage: 10,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            lastPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "subcategory", label: "Name" },
                { key: "category", label: "Category" },
                { key: "user", label: "User" },
                { key: "score", label: "Score", class: "text-center" },
                { key: "reactions", label: "Reactions", class: "text-center" },
                { key: "messages", label: "Messages", class: "text-center" },
                { key: "completed", label: "Completed", class: "text-center text-nowrap" }
            ],
            searchFeed: "",
            orderBy: "desc",
            orderByOptions: [
                { text: "Descending", value: "desc" },
                { text: "Ascending", value: "asc" }
            ],
            hasReactions: "",
            hasReactionsOptions: [
                { text: "All", value: "" },
                { text: "With reactions", value: true },
                { text: "No reactions", value: false }
            ],
        };
    },
    methods: {
        searchResults() {
            this.getAllCompletedQuestionnaires();
        },
        getAllCompletedQuestionnaires() {
            this.show = true

            //Return completed questionnaires list
            this.$http.get("/api/auth/admin/questionnaires-completed?page="+this.currentPage+"&order_by="+this.orderBy+"&has_reactions="+this.hasReactions+"&param=" + this.searchFeed)
                .then((res) => {
                    if(res != undefined) {
                        this.data = res.data.data

                        // Set pagination params
                        this.totalRows = res.data.total
                        this.lastPage = res.data.last_page
                    }
                    this.show = false
                })
                .catch((err) => {
                    console.log(err)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong, please login again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                    
                    this.show = false
            })
        },
        changePage(direction) {
            if(direction == 'prev') {
                if(this.currentPage > 1) {
                    this.currentPage--
                    this.getAllCompletedQuestionnaires()
                }
            } else {
                if(this.currentPage < this.lastPage) {
                    this.currentPage++
                    this.getAllCompletedQuestionnaires()
                }
            }
        }
    },
    mounted() {
        this.getAllCompletedQuestionnaires();
    }
}
</script>

<style scoped>
.table th, .table td {
    padding: 0rem
}
</style>